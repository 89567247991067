import * as React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import PostCard from '../components/post-card';
import { BlogPostsQuery } from '../../types/graphql-types';

const BlogIndex: React.FC<PageProps<BlogPostsQuery>> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const siteUrl = data.site.siteMetadata.siteUrl;
  const posts = data.allMarkdownRemark.nodes.filter((node) =>
    node.fields.slug.startsWith('/entry/')
  );

  const entryLists = (posts) => {
    if (posts.length === 0) {
      return <></>;
    }

    return posts
      .map((post) => {
        const title = post.frontmatter.title || post.fields.slug;
        const { date } = post.frontmatter;
        const description = post.frontmatter.description || post.excerpt;
        const { slug } = post.fields;
        const readingTime = post.fields.readingTime.text;

        return (
          <PostCard
            key={slug}
            title={title}
            date={date}
            description={description}
            slug={slug}
            readingTime={readingTime}
          />
        );
      })
      .reduce((prev, curr) => [prev, '', curr]);
  };

  /* https://tailwindcomponents.com/component/blog-page */

  return (
    <Layout location={location} title={siteTitle} siteUrl={siteUrl}>
      <Seo title={siteTitle} />
      <div className="not-prose my-12 grid grid-cols-1 gap-6 sm:grid-cols-1">
        {entryLists(posts)}
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogPosts {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "YYYY/MM/DD")
          title
          description
        }
      }
    }
  }
`;
