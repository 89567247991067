import * as React from 'react';
import { Link } from 'gatsby';

type Props = {
  key: string;
  title: string;
  date: string;
  description: string;
  slug: string;
  readingTime: string;
};

const PostCard = (props: Props): JSX.Element => {
  const { key, title, date, description, slug, readingTime } = props;

  // <!--/ Post Content-->
  return (
    <div
      className="group relative w-full bg-white dark:bg-slate-800 rounded border border-slate-200 dark:border-slate-800 shadow-lg"
      key={key}>
      <div className="absolute -inset-px rounded border-2 border-transparent opacity-0 [background:linear-gradient(var(--quick-links-hover-bg,theme(colors.sky.50)),var(--quick-links-hover-bg,theme(colors.sky.50)))_padding-box,linear-gradient(to_top,theme(colors.indigo.400),theme(colors.cyan.400),theme(colors.sky.500))_border-box] group-hover:opacity-100 dark:[--quick-links-hover-bg:theme(colors.slate.800)]" />
      <div className="relative">
        <Link to={slug} className="no-underline hover:no-underline">
          <div className="rounded-t rounded-b-none overflow-hidden">
            {/* <p className="w-full text-gray-600 text-xs md:text-sm px-6">CATEGORY</p> */}
            <div className="w-full font-bold text-xl pt-6 px-6">{title}</div>
            <p
              className="font-serif text-base pt-6 px-6 mb-5"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              itemProp="description"
            />
          </div>
          <div className="mt-auto overflow-hidden p-6">
            <div className="flex items-center justify-between">
              {/* <img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" /> */}
              <p className="text-xs md:text-sm">{date}</p>
              <p className="text-xs md:text-sm">{readingTime}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default PostCard;
