import * as React from 'react';
import Footer from '../components/footer';
import Bio from '../components/bio';

type Props = {
  location: Location;
  title: string;
  children?: unknown;
  siteUrl?: string;
};

const Layout = (props: Props): JSX.Element => {
  const { location, title, children, siteUrl } = props;
  console.log(`location: ${location}`);
  // const rootPath = `${__PATH_PREFIX__}/`
  // const isRootPath = location.pathname === rootPath
  // let header

  // if (isRootPath) {
  //   header = (
  //     <h1 className="main-heading">
  //       <Link to="/">{title}</Link>
  //     </h1>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  return (
    <>
      {/* <!--Header--> */}
      <div className="w-full h-40 md:h-72 m-0 p-0">
        <div className="container max-w-4xl mx-auto pt-16 md:pt-32 text-center break-normal">
          {/* <!--Title--> */}
          <p className="font-extrabold text-3xl md:text-5xl">{title}</p>
          {/* <p className="text-xl md:text-2xl text-gray-500">Welcome to my Blog</p> */}
        </div>
      </div>

      {/* <!--Container--> */}
      <div className="container px-4 max-w-6xl mx-auto">
        {/* <!--Nav--> */}
        <nav className="mt-0 w-full">
          <div className="container mx-auto flex items-center">
            <div className="flex w-1/2 pl-4 text-sm">
              {/* <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
                <li className="mr-2">
                  <a
                    className="inline-block py-2 px-2 text-white no-underline hover:underline"
                    href="post.html">
                    POST
                  </a>
                </li>
                <li className="mr-2">
                  <a
                    className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2"
                    href="#">
                    LINK
                  </a>
                </li>
                <li className="mr-2">
                  <a
                    className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2"
                    href="#">
                    LINK
                  </a>
                </li>
                <li className="mr-2">
                  <a
                    className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2"
                    href="post_vue.html">
                    POST_VUE
                  </a>
                </li>
              </ul> */}
            </div>

            <div className="flex w-1/2 justify-end content-center">
              {siteUrl && (
                <>
                  <a
                    className="inline-block no-underline text-slate-500 hover:text-slate-600 text-center h-10 p-2 md:h-auto md:p-4 avatar"
                    data-tippy-content="@twitter_handle"
                    href={`https://twitter.com/intent/tweet?url=${siteUrl}`}>
                    <svg
                      className="fill-current h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32">
                      <path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z" />
                    </svg>
                  </a>
                  <a
                    className="inline-block no-underline text-slate-500 hover:text-slate-600 text-center h-10 p-2 md:h-auto md:p-4 avatar"
                    data-tippy-content="#facebook_id"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}`}>
                    <svg
                      className="fill-current h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32">
                      <path d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z" />
                    </svg>
                  </a>
                </>
              )}
            </div>
          </div>
        </nav>

        <div className="w-full text-xl md:text-2xl leading-normal rounded-t">
          {/* <!--Posts Container--> */}
          {children}
          {/* <!--/ Post Content--> */}
        </div>

        {/* <!--Subscribe-->	 */}
        {/* <div className="container font-sans bg-green-100 rounded mt-8 p-4 md:p-24 text-center">
              <h2 className="font-bold break-normal text-2xl md:text-4xl">Subscribe to Ghostwind CSS</h2>
              <h3 className="font-bold break-normal font-normal text-gray-600 text-base md:text-xl">Get the latest posts delivered right to your inbox</h3>
              <div className="w-full text-center pt-4">
                <form action="#">
                  <div className="max-w-xl mx-auto p-1 pr-0 flex flex-wrap items-center">
                    <input type="email" placeholder="youremail@example.com" className="flex-1 appearance-none rounded shadow p-3 text-gray-600 mr-2 focus:outline-none" />
                    <button type="submit" className="flex-1 mt-4 md:mt-0 block md:inline-block appearance-none bg-green-500 text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow hover:bg-green-400">Subscribe</button>
                  </div>
                </form>
              </div>
            </div> */}
        {/* <!-- /Subscribe--> */}

        {/* <!--Author--> */}
        <Bio />
        {/* <!--/Author--> */}
      </div>
      <Footer title={title} />
    </>
  );
};

export default Layout;
